import React from "react";
import Login from "./Login";

const PlayAgain = props => (
  <div className="game-done">
    <div
      className="message"
      style={{
        color:
          props.gameStatus === "you lost"
            ? "red"
            : props.gameStatus === "ready?"
            ? "yellow"
            : "green",
      }}
    >
      {props.gameStatus === "you lost"
        ? "Game Over"
        : props.gameStatus === "ready?"
        ? "Ready?"
        : "Nice!"}
    </div>
    <button onClick={props.onClick}>
      {props.gameStatus === "ready?" ? "I'm Ready" : "Play Again"}
    </button>
    <Login setGamesWon={props.setGamesWon} />
  </div>
);

export default PlayAgain;
