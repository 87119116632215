export const checkShow = (state, cls) => {
  // console.log("checkShow", s, c);
  const elements = document.getElementsByClassName(cls);
  for (const d of elements) {
    // const d = elements[0];
    if (d) {
      if (state === "toggle") {
        setTimeout(() => {
          d.classList.toggle(cls + "-show");
        }, 1);
      } else {
        if (
          (state && !d.classList.contains(cls + "-show")) ||
          (!state && d.classList.contains(cls + "-show"))
        ) {
          //need this to work on mobile.
          setTimeout(() => {
            d.classList.toggle(cls + "-show");
          }, 1);
        }
      }
    }
  }
};
