import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { checkShow } from "../checkShow";

const Activity = () => {
  useFirestoreConnect({
    collection: `activity`,
    orderBy: ["dtStamp", "desc"],
    limit: 4,
    storeAs: "currentActivity",
  });

  const currentActivity = useSelector(
    state => state.firestore.ordered.currentActivity || []
  );

  React.useEffect(() => {
    const to = setTimeout(() => {
      checkShow(true, "activity");
    }, 1000);
    return () => {
      clearTimeout(to);
    };
  });

  return (
    <div className="activity-container">
      {currentActivity.map((o, i) => (
        <div key={o.dtStamp} className={`activity`}>
          {o.message}
        </div>
      ))}
    </div>
  );
};

export default Activity;
