import React from "react";
import { useSelector } from "react-redux";
import Game from "./components/Game";
import "./App.css";
import { checkShow } from "./checkShow";

const App = () => {
  const isServiceWorkerInitialized = useSelector(
    state => state.swState.serviceWorkerInitialized
  );
  const isServiceWorkerUpdated = useSelector(
    state => state.swState.serviceWorkerUpdated
  );
  const serviceWorkerRegistration = useSelector(
    state => state.swState.serviceWorkerRegistration
  );

  const [showSWInit, setShowSWInit] = React.useState(
    isServiceWorkerInitialized
  );
  const [showSWUpdated, setShowSWUpdated] = React.useState(
    isServiceWorkerUpdated
  );

  React.useEffect(() => {
    setShowSWInit(isServiceWorkerInitialized);
  }, [isServiceWorkerInitialized]);

  React.useEffect(() => {
    setShowSWUpdated(isServiceWorkerUpdated);
  }, [isServiceWorkerUpdated]);

  React.useEffect(() => {
    checkShow(showSWInit, "app-saved");
  }, [showSWInit]);

  React.useEffect(() => {
    checkShow(showSWUpdated, "app-update");
  }, [showSWUpdated]);

  const updateServiceWorker = () => {
    const registrationWaiting = serviceWorkerRegistration.waiting;
    if (registrationWaiting) {
      registrationWaiting.postMessage({ type: "SKIP_WAITING" });
      registrationWaiting.addEventListener("statechange", e => {
        if (e.target.state === "activated") {
          window.location.reload();
        }
      });
    }
  };

  return (
    <>
      <div className="app-saved">
        <div aria-live="polite" aria-atomic="true">
          Page has been saved for offline use
        </div>
        <button onClick={() => setShowSWInit(false)}>Ok</button>
      </div>
      <div className="app-update">
        <div aria-live="polite" aria-atomic="true">
          There is a new version available.
        </div>
        <button onClick={updateServiceWorker}>Update</button>
      </div>
      <Game />
    </>
  );
};

export default App;
