import React from "react";
import { useFirestoreConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";
// import { checkShow } from "../checkShow";

const HighScores = () => {
  useFirestoreConnect({
    collection: `highScores`,
    orderBy: ["gamesWon", "desc"],
    limit: 5,
    storeAs: "highScores",
  });

  const highScores = useSelector(
    state => state.firestore.ordered.highScores || []
  );

  return (
    <div className="scores-container">
      <h2>High Scores</h2>
      {highScores.map((o, i) => (
        <div key={i} className={`score`}>
          <div>{o.displayName}</div>
          <div>{o.gamesWon}</div>
        </div>
      ))}
    </div>
  );
};

export default HighScores;
