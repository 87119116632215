import { applyMiddleware, createStore, compose } from "redux";
import { combineReducers } from "redux";
import { getFirestore, firestoreReducer } from "redux-firestore";
import { getFirebase, firebaseReducer } from "react-redux-firebase";
import thunk from "redux-thunk";

export const fbConfig = {
  apiKey: "AIzaSyCmSLTulniX0Lkq87h2545aevm_u0yPy04",
  authDomain: "star-match-game-33706.firebaseapp.com",
  databaseURL: "https://star-match-game-33706.firebaseio.com",
  projectId: "star-match-game-33706",
  storageBucket: "star-match-game-33706.appspot.com",
  messagingSenderId: "897359756040",
  appId: "1:897359756040:web:b5f5c05a2951eaad60cabd",
  measurementId: "G-B4E4CSYVB4",
};

export const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Store in Firestore instead of Real Time DB
  enableLogging: false,
  enableClaims: true,
  //this doesn't work... in firestore or realtime db...
  // presence: "presence", // where list of online users is stored in database
};

export const SW_INIT = "SW_INIT";
export const SW_UPDATE = "SW_UPDATE";

const swRootReducer = (state = {}, action) => {
  switch (action.type) {
    case SW_INIT:
      return {
        ...state,
        serviceWorkerInitialized: !state.serviceWorkerInitialized,
      };
    case SW_UPDATE:
      return {
        ...state,
        serviceWorkerUpdated: !state.serviceWorkerUpdated,
        serviceWorkerRegistration: action.payload,
      };
    default:
      return state;
  }
};

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  swState: swRootReducer,
});

export const configureStore = (initialState, history) => {
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const middleware = [thunk.withExtraArgument({ getFirebase, getFirestore })];
  const createStoreWithMiddleware = composeEnhancers(
    applyMiddleware(...middleware)
  )(createStore);
  const store = createStoreWithMiddleware(rootReducer, initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    // module.hot.accept("./reducer", () => {
    //   const nextRootReducer = require("./reducer");
    //   store.replaceReducer(nextRootReducer);
    // });
  }

  return store;
};
