import React, { useState, useEffect } from "react";
import utils from "../math-utils";

import StarsDisplay from "./StarsDisplay";
import PlayNumber from "./PlayNumber";
import PlayAgain from "./PlayAgain";
import Activity from "./Activity";
import HighScores from "./HighScores";

import { useSelector } from "react-redux";
import { useFirestore } from "react-redux-firebase";

import { checkShow } from "../checkShow";

const useGameState = ({ ready }) => {
  const [stars, setStars] = useState(utils.random(1, 9));
  const [availableNums, setAvailableNums] = useState(utils.range(1, 9));
  const [candidateNums, setCandidateNums] = useState([]);
  const [secondsLeft, setSecondsLeft] = useState(10);

  const resetGame = () => {
    setStars(utils.random(1, 9));
    setAvailableNums(utils.range(1, 9));
    setCandidateNums([]);
    setSecondsLeft(10);
  };

  useEffect(() => {
    if (secondsLeft > 0 && availableNums.length > 0 && ready) {
      const timerId = setTimeout(() => setSecondsLeft(secondsLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  });

  const setGameState = newCandidateNums => {
    if (utils.sum(newCandidateNums) !== stars) {
      setCandidateNums(newCandidateNums);
    } else {
      const newAvailableNums = availableNums.filter(
        n => !newCandidateNums.includes(n)
      );
      setStars(utils.randomSumIn(newAvailableNums, 9));
      setAvailableNums(newAvailableNums);
      setCandidateNums([]);
    }
  };

  return {
    stars,
    availableNums,
    candidateNums,
    secondsLeft,
    setGameState,
    resetGame,
  };
};

const Game = () => {
  const [gamesWon, setGamesWon] = useState(0);
  const [ready, setReady] = useState(false);
  const [playAgain, setPlayAgain] = useState(false);

  const profile = useSelector(state => state.firebase.profile);
  const profileGamesWon = profile.gamesWon || 0;

  React.useEffect(() => {
    console.log("update games won from profile...");
    setGamesWon(profileGamesWon);
  }, [profileGamesWon]);

  const {
    stars,
    availableNums,
    candidateNums,
    secondsLeft,
    setGameState,
    resetGame,
  } = useGameState({ ready });

  const candidatesAreWrong = utils.sum(candidateNums) > stars;

  const gameStatus = ready
    ? availableNums.length === 0
      ? playAgain
        ? "won"
        : "you won"
      : secondsLeft === 0
      ? playAgain
        ? "lost"
        : "you lost"
      : "active"
    : "ready?";

  const auth = useSelector(state => state.firebase.auth);
  const uid = auth.uid;

  const firestore = useFirestore();

  useEffect(() => {
    if (gameStatus === "won") {
      setPlayAgain(false);
      setGamesWon(gw => {
        let newGw = gw + 1;
        if (firestore && uid) {
          firestore.collection("users").doc(uid).update({ gamesWon: newGw });
          return gw;
        } else {
          return newGw;
        }
      });
    }
    if (gameStatus === "lost") {
      setPlayAgain(false);
      if (firestore && uid) {
        firestore.collection("users").doc(uid).update({ gamesWon: 0 });
      } else {
        setGamesWon(() => {
          return 0;
        });
      }
    }
  }, [firestore, gameStatus, setGamesWon, uid]);

  useEffect(() => {
    if (ready) {
      checkShow(true, "activity-container");
      checkShow(false, "help");
    } else {
      checkShow(false, "activity-container");
      checkShow(true, "help");
    }
  }, [ready]);

  const numberStatus = number => {
    if (!availableNums.includes(number)) {
      return "used";
    }

    if (candidateNums.includes(number)) {
      return candidatesAreWrong ? "wrong" : "candidate";
    }

    return "available";
  };

  const onNumberClick = (number, currentStatus) => {
    if (currentStatus === "used" || secondsLeft === 0) {
      return;
    }

    const newCandidateNums =
      currentStatus === "available"
        ? candidateNums.concat(number)
        : candidateNums.filter(cn => cn !== number);

    setGameState(newCandidateNums);
  };

  return (
    <div className="game">
      <div className="help">
        Pick 1 or more numbers that sum to the number of stars
      </div>
      <Activity />
      <div className="body">
        <div className="container">
          <div className="left">
            <div className="status">
              <div className="games-won">Won: {gamesWon}</div>
              <div className="timer">
                Time Remaining: <span className="countdown">{secondsLeft}</span>
              </div>
            </div>
            {gameStatus !== "active" ? (
              <PlayAgain
                onClick={() => {
                  setReady(true);
                  setPlayAgain(true);
                  resetGame();
                }}
                gameStatus={gameStatus}
                setGamesWon={setGamesWon}
              />
            ) : (
              <StarsDisplay count={stars} secondsLeft={secondsLeft} />
            )}
          </div>
        </div>
        <div className="container">
          <div className="right">
            {playAgain ? (
              <>
                {utils.range(1, 9).map(number => (
                  <PlayNumber
                    key={number}
                    status={numberStatus(number)}
                    number={number}
                    onClick={onNumberClick}
                  />
                ))}
              </>
            ) : (
              <HighScores />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Game;
