import React from "react";
import gLogo from "./google-logo.svg";
import { useFirebase } from "react-redux-firebase";
import { useSelector } from "react-redux";

const Login = props => {
  const firebase = useFirebase();
  const auth = useSelector(state => state.firebase.auth);

  const signInWithGoogle = () => {
    props.setGamesWon(0);
    firebase.login({
      provider: "google",
      type: "popup",
    });
  };

  if (!auth.isEmpty)
    return (
      <button
        onClick={event => {
          event.preventDefault();
          props.setGamesWon(0);
          firebase.auth().signOut();
        }}
      >
        Logout
      </button>
    );

  return (
    <>
      <button
        onClick={event => {
          event.preventDefault();
          signInWithGoogle();
        }}
        disabled={window.location.search.indexOf("requestDemo=true") >= 0}
      >
        Sign In with <img src={gLogo} alt="Google" className="signin-image" />
      </button>
    </>
  );
};

export default Login;
